
import { defineComponent } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'
import Calendar from '@/components/molecules/Calendar.vue'
import Info from '@/assets/svg/info.svg?inline'
import Select from '@/components/atoms/CustomSelect.vue'
import dataCreateSlots from '@/base/index'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'

export default defineComponent({
  name: 'CreateSlotsSection',
  data () {
    return {
      checkedDays: [],
      selectedTime: '',
      selectedEndTime: '',
      selectedDuration: '',
      checkedStartDay: [],
      programmaticChange: false,
      isLoading: true,
      dataSlotsTime: null,
      dateStartsFrom: new Date(),
      unitData: {},
      errorSlots: null,
      selectedNewData: {
        monday: [{
          start: '10:00',
          visit_duration: 30,
          end: '19:00'
        }],
        tuesday: [{
          start: '10:00',
          visit_duration: 30,
          end: '19:00'
        }],
        wednesday: [{
          start: '10:00',
          visit_duration: 30,
          end: '19:00'
        }],
        thursday: [{
          start: '10:00',
          visit_duration: 30,
          end: '19:00'
        }],
        friday: [{
          start: '10:00',
          visit_duration: 30,
          end: '19:00'
        }],
        saturday: [{
          start: '10:00',
          visit_duration: 30,
          end: '19:00'
        }],
        sunday: [{
          start: '10:00',
          visit_duration: 30,
          end: '19:00'
        }]
      },
      selectedNewDate: null,
      selectedNewDays: null,
      selectedNewTime: {},
      selectedNewDuration: {},
      selectedNewEnd: {},
      selectedNewTimeDay: [],
      timeSlotErrorPopup: false,
      timeSlotError: null
    }
  },
  watch: {
    checkedDays: {
      handler () {
        if (this.selectedNewDays?.length === 7) {
          this.checkedDays.push('all_days')
        }
        if (!this.programmaticChange) {
          if (this.checkedDays.includes('all_days')) {
            this.programmaticChange = true
            this.checkedDays = ['all_days', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            this.selectedNewData = {
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
              sunday: []
            }
            this.selectedNewTime = {}
            this.selectedNewDuration = {}
            this.selectedNewEnd = {}
          }
        } else {
          this.programmaticChange = false
        }
        this.errorSlots = null
      }
    }
  },
  mounted () {
    this.dataSlotsTime = dataCreateSlots
    this.getUnit()
  },
  components: {
    LottieAnimation: Vue3Lottie,
    Info,
    Calendar,
    Select,
    AlertBlock,
    DefaultPopup
  },
  methods: {
    getUnit () {
      this.$store.dispatch('property/getUnit', this.$route.query.unitId).then(
        (res) => {
          this.unitData = res.data
          this.isLoading = false
          this.selectedNewDate = this.unitData?.timeslot?.date_starts_from
          if (this.unitData && this.unitData.timeslot) {
            this.selectedNewDays = Object.keys(this.unitData.timeslot.config).sort((a, b) => {
              const order = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
              return order.indexOf(a) - order.indexOf(b)
            })
            if (this.selectedNewDays.length > 0) {
              this.checkedDays = this.selectedNewDays
            }
            for (let i = 0; i < this.checkedDays.length; i++) {
              const day = this.checkedDays[i]
              const timeTitle = this.unitData?.timeslot?.config?.[day]?.start
              const timeValue = this.unitData?.timeslot?.config?.[day]?.start
              const titelDurationValue = this.unitData?.timeslot?.config?.[day]?.visit_duration
              const dratinValue = this.unitData?.timeslot?.config?.[day]?.visit_duration
              const titelEndValue = this.unitData?.timeslot?.config?.[day]?.end
              const endValue = this.unitData?.timeslot?.config?.[day]?.end
              this.selectedNewTimeDay.push(day)
              this.handleOptionBeginningTimeSelected(day, { title: timeTitle, value: timeValue })
              this.handleOptionDurationSelected(day, { title: titelDurationValue, value: dratinValue })
              this.handleOptionEndTimeSelected(day, { title: titelEndValue, value: endValue })
            }
          }
          console.log(this.selectedNewDays, 'this.selectedNewDays')
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    handleOptionBeginningTimeSelected (selectedOption, selectName) {
      selectName = selectedOption + '-' + selectName.value
      const [day, time] = selectName.split('-')
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      const newSelectedNewTime = {}
      for (let i = 0; i < this.selectedNewTimeDay.length; i++) {
        const currentDay = this.selectedNewTimeDay[i]
        if (days.includes(currentDay)) {
          const timeForCurrentDay = this.unitData?.timeslot?.config?.[currentDay]?.start
          newSelectedNewTime[currentDay] = timeForCurrentDay
        }
      }
      this.selectedNewTime = newSelectedNewTime
      if (selectedOption === this.selectedNewData[day]) {
        this.selectedTime = time
      }
      const newSelection = {
        start: time,
        visitDuration: null,
        end: null
      }
      const daySelections = this.selectedNewData[day] || []
      const newDaySelections = [...daySelections, newSelection]
      this.selectedNewData = {
        ...this.selectedNewData,
        [day]: newDaySelections
      }
      if (this.checkedDays.includes('all_days')) {
        this.selectedTime = time
      }
    },

    handleOptionDurationSelected (selectedOption, selectName) {
      selectName = selectedOption + '-' + selectName.value
      const [day, time] = selectName.split('-')
      const index = (selectedOption instanceof Element) ? selectedOption.getAttribute('data-index') : undefined
      const daySelections = this.selectedNewData[day] || []
      const updatedSelections = [...daySelections]
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      const newSelectedNewTime = {}
      for (let i = 0; i < this.selectedNewTimeDay.length; i++) {
        const currentDay = this.selectedNewTimeDay[i]
        if (days.includes(currentDay)) {
          const timeForCurrentDay = this.unitData?.timeslot?.config?.[currentDay]?.visit_duration
          newSelectedNewTime[currentDay] = timeForCurrentDay
        }
      }
      this.selectedNewDuration = newSelectedNewTime
      if (index !== undefined) {
        updatedSelections[index] = {
          ...updatedSelections[index],
          visitDuration: time
        }
      } else {
        updatedSelections.push({
          start: null,
          visitDuration: time,
          end: null
        })
      }
      this.selectedNewData = {
        ...this.selectedNewData,
        [day]: updatedSelections
      }
      if (this.checkedDays.includes('all_days')) {
        this.selectedDuration = time
      }
    },

    handleOptionEndTimeSelected (selectedOption, selectName) {
      selectName = selectedOption + '-' + selectName.value
      const [day, time] = selectName.split('-')
      const index = (selectedOption instanceof Element) ? selectedOption.getAttribute('data-index') : undefined
      const daySelections = this.selectedNewData[day] || []
      const updatedSelections = [...daySelections]
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      const newSelectedNewTime = {}
      for (let i = 0; i < this.selectedNewTimeDay.length; i++) {
        const currentDay = this.selectedNewTimeDay[i]
        if (days.includes(currentDay)) {
          const timeForCurrentDay = this.unitData?.timeslot?.config?.[currentDay]?.end
          newSelectedNewTime[currentDay] = timeForCurrentDay
        }
      }
      this.selectedNewEnd = newSelectedNewTime
      if (index !== undefined) {
        updatedSelections[index] = {
          ...updatedSelections[index],
          end: time
        }
      } else {
        updatedSelections.push({
          start: null,
          visitDuration: null,
          end: time
        })
      }
      this.selectedNewData = {
        ...this.selectedNewData,
        [day]: updatedSelections
      }
      if (this.checkedDays.includes('all_days')) {
        this.selectedEndTime = time
      }
    },
    checkedDay (day) {
      this.checkedStartDay.push(day)
      if (day === 'all_days' && !this.checkedDays.indexOf('all_days')) {
        this.checkedDays = []
      }
      if (day !== 'all_days') {
        const removeAllDays = this.checkedDays.indexOf('all_days')
        if (this.checkedDays.indexOf('all_days') === 0) {
          this.checkedDays.splice(removeAllDays, 1)
        }
        const indexToRemove = this.checkedDays.indexOf(day)
        if (indexToRemove !== -1) {
          this.checkedDays.splice(indexToRemove, 1)
        }
      }
    },
    selectDate (date, month, year) {
      const selectedDate = new Date(year, month, date.value + 1)
      const currentDate = new Date()
      if (selectedDate < currentDate) {
        console.log('date must be greater than current')
      } else {
        this.dateStartsFrom = selectedDate.getFullYear() + '-' + (selectedDate.getMonth() + 1) + '-' + (selectedDate.getDate())
        console.log(this.dateStartsFrom, 'this.dateStartsFrom')
      }
      this.selectedNewDate = null
    },
    createSlots (visitsCancel) {
      const weekdaysObject = {}

      Object.keys(this.selectedNewData).forEach(day => {
        const daySelections = this.selectedNewData[day]
        if (daySelections && daySelections.length > 0) {
          const { start, visitDuration, end } = daySelections[0]
          weekdaysObject[day] = {
            start,
            visit_duration: visitDuration,
            end
          }
          for (let i = 1; i < daySelections.length; i++) {
            const { start, visitDuration, end } = daySelections[i]
            if (start) weekdaysObject[day].start = start
            if (visitDuration) weekdaysObject[day].visit_duration = visitDuration
            if (end) weekdaysObject[day].end = end
          }
        }

        if (this.checkedDays.includes('all_days')) {
          const allDaysSelections = this.selectedNewData.all_days
          if (allDaysSelections && allDaysSelections.length > 0) {
            const { start, visitDuration, end } = allDaysSelections[0]
            Object.keys(this.selectedNewData).forEach((key) => {
              if (key !== 'all_days') {
                weekdaysObject[key] = {
                  start: this.selectedTime || '10:00',
                  visit_duration: this.selectedDuration || 30,
                  end: this.selectedEndTime || '19:00'
                }
              }
            })
          }
        }

        Object.keys(this.selectedNewData).forEach(day => {
          const daySelections = this.selectedNewData[day]
          if (daySelections && daySelections.length > 0) {
            const { start, visitDuration, end } = daySelections[0]
            weekdaysObject[day] = {
              start: start || weekdaysObject[day]?.start || '10:00',
              visit_duration: visitDuration || weekdaysObject[day]?.visit_duration || 30,
              end: end || weekdaysObject[day]?.end || '19:00'
            }
            for (let i = 1; i < daySelections.length; i++) {
              const { start, visitDuration, end } = daySelections[i]
              if (start) weekdaysObject[day].start = start
              if (visitDuration) weekdaysObject[day].visit_duration = visitDuration
              if (end) weekdaysObject[day].end = end
            }
          }
        })
      })

      if (this.checkedDays.length > 0 && Object.keys(weekdaysObject).length === 0) {
        for (let i = 0; i < this.checkedDays.length; i++) {
          const day = this.checkedDays[i]
          Object.keys(this.selectedNewData).forEach((key) => {
            if (key === day) {
              weekdaysObject[day] = {
                start: '10:00',
                visit_duration: 30,
                end: '19:00'
              }
            }
          })
        }
      }

      const selectedDays = this.checkedDays.filter((day) => day !== 'all_days')
      const filteredWeekdaysObject = Object.keys(weekdaysObject)
        .filter((day) => selectedDays.includes(day))
        .reduce((obj, key) => {
          obj[key] = weekdaysObject[key]
          return obj
        }, {})
      // if (new Date(this.dateStartsFrom).toISOString().slice(0, 10) === new Date().toISOString().slice(0, 10) && this.unitData?.timeslot?.date_starts_from !== undefined) {
      //   this.dateStartsFrom = this.unitData?.timeslot?.date_starts_from
      // }
      // if (new Date(this.dateStartsFrom) < new Date()) {
      //   this.dateStartsFrom = new Date()
      // }
      const slotsData = {
        unitId: this.$route.query.unitId,
        date_starts_from: new Date(this.dateStartsFrom).toISOString(),
        config: {
          weekdays: {
            ...filteredWeekdaysObject
          }
        },
        visits_cancel: visitsCancel
      }
      this.$store.dispatch('property/createSlots', slotsData).then(
        (res) => {
          this.$router.push(`/dashboard/unit-details?unitId=${this.$route.query.unitId}`)
        },
        (error) => {
          this.errorSlots = error.response.data.errors
          this.timeSlotError = error.response.data.message
          if (error.response.data.context === 'timeslot_update_failed') {
            this.timeSlotErrorPopup = true
          }
        }
      )
    },
    handleCloseModalSlotError () {
      this.timeSlotErrorPopup = false
    }
  }
})
